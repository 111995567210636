import axios from "axios";
import router  from "../router";


let apiBaseUrl = window._CONFIG['domianURL'] || '/jeecg-boot'

// 创建 axios 实例
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  timeout: 12000000 // 请求超时时间
})

//添加请求拦截器
service.interceptors.request.use(
  (config) => {
    
     const token = localStorage.getItem('token');
    // const token ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7InBhc3N3b3JkIjoiM2UzMjBlOTYtMzA5ZC00ZWM3LWE1MTEtN2M3N2FkNmM1NDZmIiwiZXhwaXJlIjoiMjAyNC0xMS0xMCAxMjo0OToyNSIsInVzZXJuYW1lIjoidXNlcl8yMDI0MDcxOTEyMzI1NF8wY2F6NmYifX0.9GXk3znjNKO5nk3xXmhQRfgRW8y45z15Wd6an7aTGwEMipX0.3S0m3GhtuLouP2ZPwLNbhDxlGLgPaOaJ1Hlfou'
    config.headers['X-Access-Token'] = token;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  response => {
    // 如果响应结果是成功的，直接返回response
    return response;
  },
  error => {
    // 检查错误响应的状态码
    if (error.response && error.response.data) {
      if (error.response.data.code === 401) { 
         const ua = navigator.userAgent;
        if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
         window.webkit.messageHandlers.LogAgain.postMessage("LogAgain");
        } else if (/Android/.test(ua)) {
         cwindow.handleMessage.LogAgain("LogAgain");
       }
        // const route = router.currentRoute;
        // const { loginPath, tokenKey, userInfoKey } = authConfigMap[route.path] || {
        //   loginPath: '/TraveLogin', // 默认登录页面
        //   tokenKey: 'token_Travel', // 默认token键名
        //   userInfoKey: 'userInfo_Travel' // 默认userInfo键名
        // };
        
        // // 清除对应的token和userInfo
        // localStorage.removeItem(tokenKey);
        // localStorage.removeItem(userInfoKey);
        // router.push({ path: loginPath, query: { redirectUrl: route.fullPath } });
        return;
      }
    }
    // 如果你想在错误处理后继续抛出错误，以便可以在调用时使用.catch捕获
    return Promise.reject(error);
  }
);

export {
  service as request
}










